<div class="ic-sidebar-filter">

  <div class="ic-analysis-sidebar__details__back">
    <button class="cl-btn cl-btn--text cl-btn--with-icon" type="button" (click)="onCancel()">
      <cl-icon [iconId]="'chevron-left'"></cl-icon>
      <span class="cl-btn__text">{{ 'analysis.sidebar.filters.Back to all filters' | translate }}</span>
    </button>
  </div>
  <div *ngIf="outsideClickEvent && !isDepartmentAndOrgnameFilter()" class="ic-analysis-sidebar__header">
    <span class="cl-err-label ic-analysis-sidebar__label">
      {{ 'analysis.sidebar.filters.Outside Click' | translate }}
    </span>
  </div>
  <div class="ic-analysis-sidebar__header" *ngIf="filterGroup">
    <h3 class="h4 ic-analysis-sidebar__header__title">
      <span class="ic-analysis-sidebar__label">{{ 'analysis.sidebar.filters.Filter by' | translate }}</span>
      {{ filterGroup.title }}
    </h3>
    <!-- Note: this filter group description can differ based on the current entity selected -->
    <p *ngIf="filterGroupDescription"
        [fullText]="filterGroupDescription" 
        [clTextTruncation]="{
          hideButtonLabel: ('analysis.sidebar.Hide description' | translate),
          maxLines: 3,
          showButtonLabel: ('analysis.sidebar.Show description' | translate)
         }"></p>
  </div>

  <div class="ic-analysis-sidebar__main">
    <ng-container *ngFor="let filter of filterFields">
      <cl-analysis-filter
         class="ic-analysis-sidebar__filter"
        *ngIf="showFilter(filter)"
        [filter]="filter"
        [labels]="filter.labels"
        [options]="filter.options"
        [selected]="filter.appliedValues"
        [filteredDataFound]="filter.filteredDataFound"
        (inputChange)="onFilterInputChange(filter, $event)"
        (pastedChange)="onFilterPastedChange(filter, $event)"
        (valueChange)="onFilterValueChange(filter, $event)"
        (inputFilters) = "emittedInputFilters($event)"
        [entity]="entity"
        [schemaSelected]="schemaSelected"
      ></cl-analysis-filter>
    </ng-container>
  </div>

  <div class="ic-analysis-sidebar__footer">

    <div class="ic-analysis-sidebar__details__actions">
      <button type="button" class="cl-btn cl-btn--primary-outline" (click)="onCancel()">
        {{ 'analysis.sidebar.filters.Cancel' | translate }}
      </button>
      <button *ngIf="!hasPopupSelections" type="button" class="cl-btn cl-btn--primary" (click)="onApply()">
        {{ 'analysis.sidebar.filters.Update results' | translate }}
      </button>
      <button *ngIf="getDeptFilter()" (click)="showChooseTreeModal.emit({ filters: filterFields, filter: getDeptFilter() })" type="button" class="cl-btn cl-btn--primary" >
        + {{ 'analysis.sidebar.filters.View Departments' | translate }}
    </button>
    </div>

    <div class="ic-analysis-sidebar__footer__help">
      <p *ngIf="filterGroup.helpText">{{ filterGroup.helpText }}</p>
      <p *ngIf="filterGroup.helpLink">
        <a class="cl-btn cl-btn--link cl-btn--with-icon" [href]="filterGroup.helpLink.url">
          <span class="cl-btn__text">{{ filterGroup.helpLink.label }}</span>
          <cl-icon [iconId]="'link'"></cl-icon>
        </a>
      </p>
    </div>

  </div>

</div>
