import { BrowserDetectionService } from 'components/common/services/browser-detection/browser-detection.service';
import { Component, ViewEncapsulation, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { has } from 'lodash';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { PdfServerRendererService } from 'components/rest/services/pdf-server-renderer/pdf-server-renderer.service';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';
import { ReportModel } from 'components/common/interfaces/report.model';
import { ReportPdfGeneratorService } from 'components/common/services/pdf-generator/report-pdf-generator.service';
import { TabReportModel } from 'pages/tab-report/interfaces/tab-report.model';
import { WindowRefService } from 'components/common/window/window-ref.service';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import {Router} from '@angular/router';
import {LocaleService} from 'components/common/services/locale/locale.service';

@Component({
  selector: 'ic-download-report',
  templateUrl: './download-report.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DownloadReportComponent {
  @Input() report!: ReportModel;
  @ViewChild(PopoverModalComponent, { static: false }) popover!: PopoverModalComponent;

  // tslint:disable-next-line:no-any
  public config: any;
  public pdfBase64!: string;

  constructor(
    private browserDetectionService: BrowserDetectionService,
    private environmentService: EnvironmentService,
    private pdfServerRenderer: PdfServerRendererService,
    private reportPdfGenerator: ReportPdfGeneratorService,
    private windowRef: WindowRefService,
    private cd: ChangeDetectorRef,
    private tracker: EventsTrackerService,
    private router: Router,
    private locale: LocaleService
  ) {
    this.config = this.environmentService.getEnvironment();
  }

  downloadPdf() {
    const promise = this.browserDetectionService.isIE11() ? this.requestAndDownload() : this.generateAndDownload();

    promise
      // @ts-ignore
      .catch((err: string) => console.error(err))
      // @ts-ignore
      .finally(() => {
        this.popover.closePopover();
        this.cd.detectChanges();
      });
    this.tracker.trackEvent('Reports', 'Download PDF', 'click');
  }

  exportPdf() {
    this.reportPdfGenerator.reportToPdfBase64(this.report)
      .then((base64) => {
        this.pdfBase64 = base64;
        this.cd.detectChanges();
        return this.pdfBase64;
      });
  }

  generateAndDownload() {
    return this.reportPdfGenerator.downloadReportPdf(this.report);
  }

  requestAndDownload() {
    return this.pdfServerRenderer.fetch(this.getShareUrl(this.report), this.report.title);
  }

  private getShareUrl(report: ReportModel|TabReportModel) {
    if (has(report, 'reports')) {
      return this.getTabReportUrl(report as TabReportModel);
    } else {
      return this.getReportUrl(report as ReportModel);
    }
  }

  private getReportUrl(report: ReportModel) {
    if (report.institutionProfile) {
      return this.windowRef.nativeWindow().location.href;
    }
    return this.locale.href(this.router.createUrlTree(['/report', report.id]).toString());
  }

  private getTabReportUrl(report: TabReportModel) {
    return this.locale.href(this.router.createUrlTree(['/tabreport', report.id]).toString());
  }

}
