export const environmentInt2Config = {
  hostnames: [
    'incites.int2.dev-incites.com',
    'localhost',
    '127.0.0.1'
  ],
  showDebugLocales: true,
  feature: {
    amchartsPOC: true,
    downloadPdf: true,
    explorerTour: false,
    geoMapZoom: false,
    pasteFilterValues: true,
    tooltipsGuide: true,
    unlockRank: true,
    treemapPOC: true,
    multiIndicatorTrendGraphPOC: true,
    trendGraphPOC: true,
    fiveYearTrendGraphPOC: true,
    retainAnalysisOnDatasetSwitch: true,
    barAmchartsPOC: true,
    heatmapAmchartsPOC: true,
    wordCloudPOC: true,
    cdxHeaderFooter: true,
    pieAmchartsPOC: true,
    groups: true,
    checkboxBulk: true,
    moveBulkButton: true,
    shareGroup : true
  },
  oneTrustKey: 'bc3456ed-a2e0-48a3-bfa6-9d75f2d1de0e',
  pasteFilterValues: {
    issn: true,
    people: {
      assprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      clbrprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      personIdType: ['allUniqueId', 'orcId', 'researcherId'],
      singlePrsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
    },
  },
  splitioKey: 'fgp6avmsapk3k53brakmv4c046ellqo5j8d3',
  wos: 'https://apps-uw2.int2.dev-wos.com/',
  gatewayLink: 'https://links.dev-snapshot.clarivate.com/api/gateway',
  derwentGatewayLink: 'https://api.dev-snapshot.clarivate.com/api/gateway',
  cookieBannerScriptId: '0f5e5dc7-7949-4ad1-b44c-ca62a091ce8a-test',
  rhnHost : 'https://research-horizon-navigator.apps.dev-snapshot.clarivate.com',
  ridHost: 'https://api.dev-snapshot.clarivate.com/wos/author/record'
};