import {
  AnalysisAppliedFiltersModel,
  AnalysisFilterGroupModel,
  AnalysisFilterModel,
  AnalysisFilterOptionParamsModel
} from 'pages/analysis/models';
import { AnalysisStateFiltersModel } from 'pages/analysis/models/analysis-state.model';
import { AppliedFilterValuesModel } from '@ic/component-lib/src/components/modules/applied-filters/models';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { FilterOptionModel } from 'pages/tab-report/interfaces';
import { FiltersResponseModel, FilterModel, FilterGroupModel } from 'components/analytics/interfaces/filter.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isPlainObject, isArray, isString, assign, isUndefined, find, pick, clone, isEqual, intersection, includes, cloneDeep, isBoolean, isNumber } from 'lodash';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import { Object } from 'core-js';
import {Observable, forkJoin, of, BehaviorSubject} from 'rxjs';
import {environmentCommonConfig} from 'components/app/services/environment/environment-common.config';

@Injectable()
export class AnalysisFiltersService {
  private envConfig: {[key: string]: any}; // tslint:disable-line:no-any
  issnOrSource: BehaviorSubject<string> = new BehaviorSubject<string>('jrnname');
  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private metadataRepository: MetadataRepositoryService,
  ) {
    this.envConfig = this.environmentService.getEnvironment();
  }

  flattenFilters(filters: AnalysisFilterModel[]) {
    return filters.reduce((result: AnalysisFilterModel[], filter) => {
      return result.concat(this.flattenFilter(filter));
    }, []);
  }

  getAnalysisFilterData(datasetId: string, entityId: string, tileId: string) {
    return this.metadataRepository.getFiltersRx(datasetId, entityId, tileId);
  }

  getFilterOptions(filter: AnalysisFilterModel, params: AnalysisFilterOptionParamsModel = {}): Observable<FilterOptionModel[]> {
    const optionsFromArray = (src: FilterOptionModel[]) => src.map(opt => ({ ...opt, label: opt.label || opt.text as string, hidden : opt.hidden }));
    const {source, type, prefetch, title} = filter;

    if (type === 'boolean') {
      if (isArray(source)) {
        return of(optionsFromArray(source as FilterOptionModel[]).filter((option) => option.value === true));
      } else {
        return of(optionsFromArray([{value: 1, text: title}]));
      }
    } else if (isArray(source)) {
      if (isPlainObject(source[0])) {
        return of(optionsFromArray(source as FilterOptionModel[]));
      } else {
        return of((source as string[]).map(this.convertValueToOption));
      }
    } else if (isString(source) && prefetch) {
      return this.requestFilterOptions(source, params);
    }

    return of([]);
  }

  getFilterOptionsRequestParams(
    filter: AnalysisFilterModel,
    eventValue: string,
    filterFields: AnalysisFilterModel[],
    filterValues: { [filter: string]: AppliedFilterValuesModel; }
  ) {
    let params: AnalysisFilterOptionParamsModel = { q: eventValue };

    if (!isUndefined(filter.dependsOn)) {
      filter.dependsOn.forEach((dependsOn: string) => {
        let dependsOnItem = filterValues[dependsOn];

        if (!isUndefined(dependsOnItem) && isPlainObject(dependsOnItem) && !isUndefined(dependsOnItem.is)) {
          params[dependsOn] = dependsOnItem.is as string;
        } else {
          const dependsOnItemOrUndefined = filterFields.find((field) => field.name === dependsOn);

          if (!isUndefined(dependsOnItemOrUndefined) && !dependsOnItemOrUndefined.hidden) {
            let appliedValues = dependsOnItemOrUndefined.appliedValues;
            let defaultValue;
            if (dependsOnItemOrUndefined.required && dependsOnItemOrUndefined.options && dependsOnItemOrUndefined.options.length
              && dependsOnItemOrUndefined.noRequiredDeFaultValue) {
              defaultValue = { is: dependsOnItemOrUndefined.options![0].value };
            } else {
              defaultValue = this.getFilterDefaults(dependsOnItemOrUndefined);
            }

            if (!isUndefined(appliedValues) && !isUndefined(appliedValues.is)) {
              params[dependsOn] = appliedValues.is as string;
            } else if (defaultValue && Object.keys(defaultValue).length) {
              params[dependsOn] = defaultValue.is as string;
            }
          }
        }
      });
    }
    return params;
  }

  getFilterOptionsRequestParamsForFilter(
    filter: AnalysisFilterModel,
    filterFields: AnalysisFilterModel[],
  ) {
    let params: AnalysisFilterOptionParamsModel = {};
    let filterValues: { [filter: string]: AppliedFilterValuesModel; } = {};

    filterFields.forEach((filterValue) => {
      if (!isUndefined(filterValue.name) && !isUndefined(filterValue.appliedValues)) {
        filterValues[filterValue.name] = cloneDeep(filterValue.appliedValues);
      }
    });
    if (!isUndefined(filter.dependsOn)) {
      filter.dependsOn.forEach((dependsOn: string) => {
        let dependsOnItem = filterValues[dependsOn];

        if (!isUndefined(dependsOnItem) && isPlainObject(dependsOnItem) && !isUndefined(dependsOnItem.is)) {
          params.q = dependsOnItem.is as string;
        } else {
          const dependsOnItemOrUndefined = filterFields.find((field) => field.name === dependsOn);

          if (!isUndefined(dependsOnItemOrUndefined) && !dependsOnItemOrUndefined.hidden) {
            let appliedValues = dependsOnItemOrUndefined.appliedValues;
            let defaultValue = this.getFilterDefaults(dependsOnItemOrUndefined);

            if (!isUndefined(appliedValues) && !isUndefined(appliedValues.is)) {
              params.q = appliedValues.is as string;
            } else if (defaultValue && Object.keys(defaultValue).length) {
              params.q = defaultValue.is as string;
            }
          }
        }
      });
    }
    return params;
  }

  getValuesFromAppliedValues(appliedValues?: AppliedFilterValuesModel): FilterOptionModel[] {
    const keys = Object.keys(appliedValues).filter((key) => (key === 'is' || key === 'not') && appliedValues![key]);

    if (keys.length < 1 || keys[0] === 'not') {
      return [];
    }

    const availableKey = keys[0] as 'is' | 'not';
    const values = appliedValues![availableKey];

    if (isArray(values)) {
      return (values as string[]).map(this.convertValueToOption);
    } else {
      // @ts-ignore
      return [this.convertValueToOption(values!)];
    }
  }

  sortFiltersByOrder(filterA: AnalysisFilterModel, filterB: AnalysisFilterModel) {
    const orderA = filterA.order || 0;
    const orderB = filterB.order || 0;
    if (orderA > orderB) return 1;
    else if (orderA < orderB) return -1;
    else return 0;
  }

  getFiltersFromResponse(
    response: FiltersResponseModel,
    stateValues: any, // tslint:disable-line: no-any
    entityId: string
  ): Observable<AnalysisStateFiltersModel> {
    const period = assign(response.period, { updateOnChanged: true }) as FilterModel;
    const search = assign(response.search, { updateOnChanged: true }) as FilterModel;
    let allFilters = [period, search].concat(response.filters);
    const requiredIndependentFilterNames = Array.from(
      allFilters.reduce(this.getRequiredIndependentFilterNamesSet, new Set<string>())
    );

    allFilters = allFilters.map((fltr) => {
      if (requiredIndependentFilterNames.includes(fltr.name)) {
        fltr.required = true;
      }
      return fltr;
    });

    const filtersGroups = this.getGroupedFilters({ filters: allFilters, groups: response.groups });

    const independentFilters$: Observable<AnalysisFilterModel[]> = forkJoin(
      allFilters.filter((filter) => !filter.dependsOn).map((filter) => this.getAnalysisFilter(filter, stateValues[filter.name]))
    ).pipe(map((independentFilters) => independentFilters));

    return independentFilters$.pipe(
      mergeMap((independentFilters) => {
        const dependentFiltersObservables = allFilters
          .filter((filter) => !!filter.dependsOn && filter.dependsOn.length === 1)
          .map((filter) => this.getAnalysisFilter(filter, stateValues[filter.name], independentFilters));
        const dependentFilters$: Observable<AnalysisFilterModel[]> = forkJoin(dependentFiltersObservables);

        return dependentFilters$.pipe(
          concatMap((dependentFilters) => {
            const doublyDependentFiltersObservables = allFilters
              .filter((filter) => !!filter.dependsOn && filter.dependsOn.length > 1)
              .map((filter) => this.getAnalysisFilter(filter, stateValues[filter.name], independentFilters.concat(dependentFilters)));
            const doublyDependentFilters$ = forkJoin(doublyDependentFiltersObservables);

            return doublyDependentFiltersObservables.length ?
              doublyDependentFilters$.pipe(map(doublyDependentFilters => dependentFilters.concat(doublyDependentFilters))) :
              of(dependentFilters);
          }),
          map((dependentFilters) => {
            let filters: AnalysisFilterModel[] = independentFilters.concat(dependentFilters);
            const headerFilters = this.getHeaderFilters(filters, entityId);

            return { filters, filtersGroups, headerFilters, values: this.getFiltersValues(filters) };
          })
        );
      })
    );
  }

  getFiltersValues(filters: AnalysisFilterModel[]) {
    return filters.reduce((acc, filter) => {
      if (this.isFilterApplied(filter)) {
        acc = assign(acc, { [filter.name]: filter.appliedValues });
      }
      return acc;
    }, {});
  }

  getFilterDefaults(
    filter: AnalysisFilterModel,
    filterValue?: AppliedFilterValuesModel
  ): AppliedFilterValuesModel {
    let appliedValues: AppliedFilterValuesModel = {};
    if (filterValue) {
      appliedValues = filterValue;
    } else if (filter.name === 'period') {
      appliedValues = { is: this.getPeriodFilterDefaults(filter) };
    } else if (filter.required && filter.options && filter.options.length > 0 && !filter.noRequiredDeFaultValue) {
      appliedValues = { is: filter.options[0].value as string };
    } else if (filter.name === 'locationType') {
      appliedValues = { is: this.getLocationTypeFilterDefaults(filter) };
    } else if (filter.name === 'earlyAccess' && filter.options) {
      appliedValues = { is: [filter.options[0].value as string] };
    } else if (filter.name === 'orgtype' && this.metadataRepository.currentEntity === 'department') {
      appliedValues = { is: 'Academic'};
    }

    return appliedValues;
  }

  isFilterDefault(filter: AnalysisFilterModel) {
    const defaultValue = this.getFilterDefaults(filter);
    return isEqual(filter.appliedValues, defaultValue);
  }

  getGroupedFilters(data: any): AnalysisFilterGroupModel[] { // tslint:disable-line: no-any
    return data.groups.map((group: any) => { // tslint:disable-line: no-any
      return {
        ...group,
        filters: data.filters
          .filter((filter: any) => filter.path === `${group.path}.${filter.name}`) // tslint:disable-line: no-any
          .sort(this.sortFiltersByOrder)
          .map((filter: any) => this.addChildrenToFilter(filter, data.filters)), // tslint:disable-line: no-any
      };
    });
  }

  isFilterApplied({appliedValues}: AnalysisFilterModel) {
    if (appliedValues) {
      const value = appliedValues[appliedValues.is ? 'is' : 'not'];

      return value && isArray(value) ? value.length > 0 : !!value;
    }

    return false;
  }

  getSourceWithParamsAndBody(source: string, params: AnalysisFilterOptionParamsModel = {}): [string, string[]] {
    let body: string[] = [];
    let sourceParams = [];
    let sourceWithParams = source;

    if (!isUndefined(params) && !isUndefined(params.q) && (isString(params.q) || isArray(params.q))) {
      body = isString(params.q) ? params.q.split(/[\s,;]+/) : params.q;

      for (let param in params) {
        if (param !== 'q') {
          let paramValue = params[param];

          if (isArray(paramValue)) {
            sourceParams.push(
              ...paramValue.map((value) => {
                return [encodeURIComponent(param), encodeURIComponent(value)].join('=');
              })
            );
          } else {
            sourceParams.push([encodeURIComponent(param), encodeURIComponent(paramValue)].join('='));
          }
        }
      }
      if (!sourceWithParams.match(/\?/)) {
        sourceWithParams += '?';
      }
      if (sourceParams.length > 0) {
        if (sourceWithParams.match(/\?[^&]+(&[^&])*$/)) {
          sourceWithParams += '&';
        }
        sourceWithParams += sourceParams.join('&');
      }
    }
    return [sourceWithParams, body];
  }

  // tslint:disable-next-line:no-any
  getMatchingValues(currentValues: any, matchingValues: any) {
    let currentValuesByValue: { [x: string]: { label: string, value: string }; } = {};
    let updatedValues = clone(currentValues);

    currentValues.forEach((currentValue: { label: string, value: string }) => {
      currentValuesByValue[currentValue.value] = currentValue;
    });
    matchingValues.forEach((matchingValue: { label: string, value: string }) => {
      if (isUndefined(currentValuesByValue[matchingValue.value])) {
        updatedValues.push(matchingValue);
      }
    });

    return updatedValues;
  }

  // tslint:disable-next-line:no-any
  getMatchingIsOrNotValues(currentValues: any, matchingValues: any) {
    let currentValuesByValue: { [x: string]: string } = {};
    let updatedValues = clone(currentValues);

    if (isUndefined(currentValues.is)) {
      currentValues.is = [];
      updatedValues.is = [];
    } else {
      currentValues.is.forEach((currentValue: string) => {
        currentValuesByValue[currentValue] = currentValue;
      });
    }
    matchingValues.forEach((matchingValue: { label: string, value: string }) => {
      if (isUndefined(currentValuesByValue[matchingValue.value])) {
        updatedValues.is.push(matchingValue.value);
      }
    });

    return updatedValues;
  }

  // tslint:disable-next-line:no-any
  getInputValueWithoutMatchingValues(inputValue: string, matchingValues: any) {
    let currentValuesByValue: { [x: string]: { index: number, value: string }; } = {};
    let currentValues = inputValue.split(/[,;]+/);

    currentValues.forEach((currentValue: string, index: number) => {
      currentValuesByValue[currentValue] = { index: index, value: currentValue };
    });

    matchingValues.forEach((matchingValue: { label: string, value: string }) => {
      if (!isUndefined(currentValuesByValue[matchingValue.value])) {
        delete currentValuesByValue[matchingValue.value];
      }
    });
    return currentValues.filter((currentValue: string) => {
      return !isUndefined(currentValuesByValue[currentValue]);
    }).join(',');
  }

  shouldPostRequestFilterOptions(source: string, params: AnalysisFilterOptionParamsModel = {}): boolean {
    if (isUndefined(this.envConfig)) {
      this.envConfig = this.environmentService.getEnvironment();
    }
    if (!this.envConfig.feature.pasteFilterValues) {
      return false;
    }

    return ['pasteFilterValues', 'postFilterListValues'].some((postFilterValuesKey) => {
      SOURCE_LOOP:
      for (let sourceType in this.envConfig[postFilterValuesKey]) {
        if (source.match(new RegExp(sourceType + '(\\?.*?)?$'))) {
          if (typeof this.envConfig[postFilterValuesKey][sourceType] === 'boolean') {
            return this.envConfig[postFilterValuesKey][sourceType];
          } else {
            for (let param in params) {
              if (!isUndefined(this.envConfig[postFilterValuesKey][sourceType][param])) {
                if (isArray(this.envConfig[postFilterValuesKey][sourceType][param])) {
                  if (includes(this.envConfig[postFilterValuesKey][sourceType][param], params[param])) {
                    return true;
                  }
                } else if (this.envConfig[postFilterValuesKey][sourceType][param] === params[param]) {
                  return true;
                }
              }
            }
          }
          break SOURCE_LOOP;
        }
      }
      return false;
    });
  }

  mapOptionForRequestFilterOptions(optionItem: string | FilterOptionModel) {
    if (isPlainObject(optionItem)) {
      let item: FilterOptionModel = optionItem as FilterOptionModel;

      item.label = item.label || item.text;
      if (item.options) {
        item.options = (item.options as string[]).map(this.convertValueToOption);
      }
      return item;
    } else if (isString(optionItem)) {
      return this.convertValueToOption(optionItem);
    }
    return optionItem;
  }

  postRequestFilterOptions(source: string, params: AnalysisFilterOptionParamsModel = {}): Observable<FilterOptionModel[]> {
    if (!this.shouldPostRequestFilterOptions(source, params)) {
      return this.requestFilterOptions(source, params);
    }
    let [sourceWithParams, body] = this.getSourceWithParamsAndBody(source, params);

    return this.http.post<any[]>(sourceWithParams, body) // tslint:disable-line:no-any
      .pipe(
        map(options => options.map(this.mapOptionForRequestFilterOptions.bind(this)))
      );
  }

  requestFilterOptions(source: string, params: AnalysisFilterOptionParamsModel = {}): Observable<FilterOptionModel[]> {
    return this.http.get<any[]>(source, { params }) // tslint:disable-line:no-any
      .pipe(
        map(options => options.map(this.mapOptionForRequestFilterOptions.bind(this)))
      );
  }

  updateFilterGroups(metadata: FiltersResponseModel, entityId: string) {
    let { filters, groups } = metadata;

    groups = groups.reduce((acc, group) => {
      if (group.path.indexOf('.') > 0) {
        const newGroup = assign(group, { path: group.path.split('.')[1] });

        acc.push(newGroup);
      }

      return acc;
    }, [] as FilterGroupModel[]);

    filters = filters.map((filter) => {
      filter.path = filter.path.slice(filter.path.indexOf('.') + 1);

      if (filter.path.indexOf('.') < 0) {
        filter.path = filter.path + '.' + filter.path;
      }

      return filter;
    });

    const signleFilterGroups = filters.reduce((acc, filter) => {
      const pathParts = filter.path.split('.');

      if (pathParts.length === 2 && pathParts[0] === pathParts[1]) {
        const { name, title } = filter;

        if (filter?.filterDescription) {
          acc.push({ name, title, path: pathParts[0], description: filter.filterDescription });
        } else {
          acc.push({ name, title, path: pathParts[0] });
        }
      }
      return acc;
    }, [] as FilterGroupModel[]);

    groups = this.setFirstFilterGroups(signleFilterGroups.concat(groups), entityId);
    return assign(metadata, { filters, groups });
  }

  setFirstFilterGroups(groups:  FilterGroupModel[], entityId: string): FilterGroupModel[] {
    // @ts-ignore
    let firstFilterGroups = environmentCommonConfig.firstFilterGroups[entityId];
    if (firstFilterGroups && firstFilterGroups.length > 0) {
      let firstGroups = groups.filter(({path}) => firstFilterGroups.includes(path));
      firstGroups.sort((a, b) => firstFilterGroups.indexOf(a.path) - firstFilterGroups.indexOf(b.path));
      groups = [...firstGroups, ...groups.filter(group => !firstFilterGroups.includes(group.path))];
    }
    return groups;
  }

  formatFilterValue(filter: AnalysisFilterModel, value: Partial<AppliedFilterValuesModel>): Partial<AppliedFilterValuesModel> {
    const state = !!value.not ? 'not' : 'is';
    const val = value.is || value.not!;
    let finalVal;

    switch (filter.type) {
      case 'boolean':
        finalVal = isArray(val) ? !!val[0] : !!val;
        break;
      default:
        finalVal = val;
    }

    return {[state]: finalVal};
  }

  private flattenFilter(filter: AnalysisFilterModel): AnalysisFilterModel[] {
    let filters = [filter];
    if (filter.children) {
      filter.children.forEach(child => {
        filters = filters.concat(this.flattenFilter(child));
      });
    }
    return filters;
  }

  private convertValueToOption(value: string): FilterOptionModel {
    return {
      label: value,
      value: value,
    };
  }

  private addChildrenToFilter(filter: AnalysisFilterModel, filters: AnalysisFilterModel[]): AnalysisFilterModel {
    if (filter.name === filter.type) {
      const children = filters
        .filter(child => child.path === `${filter.path}.${child.name}`)
        .sort(this.sortFiltersByOrder);

      if (children.length) {
        filter.children = children.map(child => {
          this.addChildrenToFilter(child, filters);
          return child;
        });
      }
    }

    return filter;
  }

  private getAnalysisFilterChoice(filter: FilterModel) {
    const analysisFilterChoice = this.envConfig['analysisFilterChoice'];
    const defaultChoice = analysisFilterChoice && analysisFilterChoice['default'] || 'checkbox';
    const getMappings = (typeOrChoice: string) => {
      const property = filter[typeOrChoice];
      const mapping = analysisFilterChoice[typeOrChoice][property];

      return !isUndefined(property) && !isUndefined(mapping) ? mapping : undefined;
    };

    if (isUndefined(analysisFilterChoice)) { // If we have lost the config
      return isUndefined(filter.choice) ? defaultChoice : filter.choice;
    }

    const possibleMappings = ['choice', 'type'].map(getMappings).filter((mapping) => !isUndefined(mapping));

    return possibleMappings[0] || defaultChoice;
  }

  private getAnalysisFilter(
    filter: FilterModel,
    filterValue: AppliedFilterValuesModel,
    independentFilters?: AnalysisFilterModel[]
  ): Observable<AnalysisFilterModel> {
    const group = '';
    const required = filter.choice === 'required' || filter.name === 'period' || filter.name === 'locationType' || filter.required;
    let sourceParams;

    filter.choice = this.getAnalysisFilterChoice(filter);

    if (filter.dependsOn && independentFilters) {
      const dependsOnFilters = independentFilters.filter((fltr) => filter.dependsOn.includes(fltr.name));
      const dependsOnValues = dependsOnFilters.map(fltr => fltr.appliedValues!.is);
      sourceParams = filter.dependsOn.reduce(
        (acc: { [index: string]: string }, name: string, i: number) => assign(acc, { [name]: dependsOnValues[i] }),
        {}
      );
    }

    const options$: Observable<FilterOptionModel[]> = this.getFilterOptions(filter as AnalysisFilterModel, sourceParams);

    return options$.pipe(
      map((options) => assign(filter, { group, options, required }) as AnalysisFilterModel),
      map((fltr) => assign(fltr, { appliedValues: this.getFilterDefaults(fltr, filterValue) }))
    );
  }

  private getPeriodFilterDefaults(filter: AnalysisFilterModel): number[] {
    filter.last5Years = [new Date().getFullYear() - 5, new Date().getFullYear() - 1];
    return filter.last5Years!;
  }

  private getLocationTypeFilterDefaults(filter: any): string { // tslint:disable-line:no-any
    return filter.options[0].value ;
  }

  getHeaderFilters(filters: AnalysisFilterModel[], entityId: string, datasetId?: string): AnalysisFilterModel[] {
    switch (entityId) {
      case 'person':
        return filters.filter((fltr) => ['personIdTypeGroup', 'personIdType', 'personId'].includes(fltr.name));
      case 'organization':
        return filters.filter((fltr) => fltr.name === 'orgname');
      case 'region':
        return filters.filter((fltr) => fltr.name === 'locationType' || fltr.name === 'location');
      case 'subject':
        return filters.filter((fltr) => ['schema', 'schemalevel', 'alcNoTopicAssigned', 'sbjname'].includes(fltr.name));
      case 'journal':
        if (datasetId === '1') {
          return filters.filter((fltr) => fltr.name === 'jrnname');
        } else {
          return filters.filter((fltr) => fltr.name === 'jrnname' || fltr.name === 'issn' || fltr.name === 'jrnkey');
        }
      case 'funder':
        return filters.filter((fltr) => ['funderType', 'fundingDataSource', 'funderOrPublisher'].includes(fltr.name));
      default:
        return [];
    }
  }

  private getRequiredIndependentFilterNamesSet(acc: Set<string>, fltr: FilterModel) {
    if (fltr.choice === 'required' && fltr.dependsOn) {
      fltr.dependsOn.forEach((fltrName: string) => acc.add(fltrName));
    }
    return acc;
  }

  isMatching(values: AnalysisAppliedFiltersModel, showOn: {[p: string]: {is: Array<string|boolean|number>, isCheckbox: boolean}}, fltrName: string) {
    const transformValue = (value: string|string[]|number|number[]|boolean|{ [key: string]: string; }): string|number|boolean => {
      return (isBoolean(value) || isString(value) || isNumber(value)) ? value : isArray(value) ? value[0] : value.name;
    };
    let currentValue = values && values[fltrName] && values[fltrName].is;
    const toShowValue = showOn[fltrName].is;
    const isCheckBox = showOn[fltrName].isCheckbox;
    if (isCheckBox && currentValue === undefined) {
      // @ts-ignore
      currentValue = false;
    }
    if (isUndefined(currentValue)) {
      return false;
    }
    return toShowValue.includes(transformValue(currentValue));
  }

  async updateFilter(
    filters: AnalysisFilterModel[],
    filterName: string,
    event: AnalysisAppliedFiltersModel
  ) {
    const filter = find(filters, { name: filterName });
    const filterValue = pick(event[filterName], ['is', 'not']) as Partial<AppliedFilterValuesModel>;
    const isFilterUpdated = filter && filterValue && !isEqual(filter.appliedValues, filterValue);

    if (filter) {
      const allShowOnFilter = filters.filter(({showOn}) => showOn && Object.keys(showOn).includes(filterName));
      if (allShowOnFilter && allShowOnFilter.length) {
        allShowOnFilter.forEach((showOnFilter) => {
          const {showOn, required} = showOnFilter;
          if (!this.isMatching(event, showOn!, filterName)) {
            if (required) {
              const defaultValue = this.getFilterDefaults(showOnFilter);
              showOnFilter.appliedValues = defaultValue;
            } else {
              showOnFilter.appliedValues = {};
            }
          }
        });
      }

      const {dependent, interDependent} = filter;

      if (isFilterUpdated) {
        filter.appliedValues = filterValue;
      }

      let allDependentSet = new Set<string>();
      if (interDependent) {
        interDependent.forEach(async (idFilterName) => {
          const idFilter = find(filters, { name: idFilterName });

          if (idFilter) {
            const updated = this.updateInterDependentFilter(filter, idFilter);

            if (updated && idFilter.dependent) {
              idFilter.dependent.forEach(dependentFilterName => {
                const dependentFilter =  find(filters, { name: dependentFilterName });
                if ((dependentFilter && !dependentFilter.interDependent) || dependentFilter && dependentFilter.interDependent &&
                  intersection(dependentFilter.interDependent, Object.keys(event)).length)
                  return;

                allDependentSet.add(dependentFilterName);
              });
            }
          }
        });
      }

      if (dependent) {
        dependent.forEach((dependentFilterName: string) => {
          if (!Object.keys(event).includes(dependentFilterName)) {
            allDependentSet.add(dependentFilterName);
          }
        }, allDependentSet);
      }

      for (let fltrName of Array.from(allDependentSet)) {
        await this.updateDependentFilter(filters, fltrName);
      }
    }
  }

  private updateDependentFilter(filters: AnalysisFilterModel[], filterName: string): Promise<void> {
    const filter = find(filters, { name: filterName });

    if (filter) {
      if (filter.prefetch) {
        const dependsOnFilters = filters.filter((fltr) => filter.dependsOn!.includes(fltr.name));
        const dependsOnValues = dependsOnFilters.map(fltr => fltr.appliedValues!.is);
        const sourceParams = filter.dependsOn!.reduce(
          (acc: { [index: string]: string }, name: string, i: number) => assign(acc, { [name]: dependsOnValues[i] }),
          {}
        );

        return this.getFilterOptions(filter, sourceParams).pipe(
          map((options) => {
            filter.options = options;
            filter.appliedValues = this.getFilterDefaults(filter);
          })
        ).toPromise();
      } else {
        filter.appliedValues = {};

        if (filter.choice === 'search') {
          filter.options = [];
        }
      }
    }

    return Promise.resolve();
  }

  private updateInterDependentFilter(
    initialFltr: AnalysisFilterModel,
    interDependentFltr: AnalysisFilterModel
  ): boolean {
    const isInterDependentApplied = this.isFilterApplied(interDependentFltr);

    if (interDependentFltr.required || isInterDependentApplied) {
      interDependentFltr.appliedValues = clone(initialFltr.appliedValues);
      interDependentFltr.options = clone(initialFltr.options);

      return true;
    }

    return false;
  }

  setISSNorSourceName(type: string) {
    this.issnOrSource.next(type);
  }

}
