import { Injectable } from '@angular/core';
import {newTracker, setUserId, trackPageView, trackStructEvent} from '@snowplow/browser-tracker';
import { WindowRefService } from '../../../common/window/window-ref.service';
import { UserModel } from 'components/auth/interfaces/user.model';
import { EventTrackerModel } from 'components/common/interfaces/event-tracker.model';

@Injectable()
export class SnowplowService implements EventTrackerModel {

  private static CLARIVATE_IGLU_SCHEMA = 'iglu:ne.clarivate.com/usage/jsonschema/2-0-0';
  private static userProps: object = {};
  // tslint:disable-next-line:no-any
  private static CONTEXT: any;

  constructor(
    private windowRef: WindowRefService
  ) {
    this.setSnowplowTracker();
  }

  setSnowplowTracker() {
    let environment = this.setEnvironment();
    newTracker('Incites-Tracker', environment, {
      encodeBase64: false,
      appId: 'incites',
      eventMethod: 'get',
      platform: 'web',
      discoverRootDomain: true,
      bufferSize: 1, // Add this line to fix outQueue issue
      // postPath: '/com.snowplowanalytics.snowplow/tp2', // Add this if not using default path
      // retryFailedRequests: false
    });
  }

  setEnvironment() {
    switch (this.windowRef.nativeWindow().location.hostname) {
      case 'localhost':
        return 'localhost:4444';
      case 'incites.clarivate.com':
        return 'snowplow-collector.userintel.prod.sp.aws.clarivate.net';
      case 'incites.int2.dev-incites.com':
      case 'incites.qa2.dev-incites.com':
      case 'incites.perf1.dev-incites.com':
      default:
        return 'snowplow-collector.staging.userintel.dev.sp.aws.clarivate.net';
    }
  }

  init(user: UserModel) {
    SnowplowService.userProps = this.mapSnowplowFields(user);
    SnowplowService.CONTEXT = [{
      schema: SnowplowService.CLARIVATE_IGLU_SCHEMA,
      data: SnowplowService.userProps,
    }];
  }

  mapSnowplowFields(user: UserModel) {
    const result: {
      uid?: number,
      userid?: string,
      customerid?: number,
      custgrpid?: string,
      institution?: string,
      subsid?: string,
      shibid?: string,
      ipset?: string,
      user1?: string,
      user2?: string,
      account1?: string,
      account2?: string,
    } = {
      uid: user.userId,
      userid: user.name,
      customerid: user.customerId,
      custgrpid: String(user.customerId),
      institution: user.customerName,
      subsid: user.subscriptionGroupId,
      shibid: user.scopeValue,
      ipset: user.ipSetId,
      user1: user.accessMethod,
      user2: user.truid,
      account1: user.ipSetIdName,
      account2: user.shibOrg
    };

    Object.keys(result).forEach(
      // tslint:disable-next-line:no-any
      (key) => (result as any)[key] === undefined && delete (result as any)[key]
    );
    return result;
  }

  trackEvent(
    category: string,
    action?: string,
    label?: string|null,
    value?: number|null|string,
    ...rest: any[] // tslint:disable-line:no-any
  ) {
    // rest[0] impressionSet
    // rest[1] impressionId
    const property = rest[0] && rest[1] ? rest[0] + ':' + rest[1] : '';

    action = action as string;
    label = label as string;
    value = value as number;
    trackStructEvent({ category, action, label, property, value, context: SnowplowService.CONTEXT });

  }

  trackPageView (name: string|undefined) {
    if (name) {
      setTimeout(() => {
        trackPageView({
          title: name,
          context: SnowplowService.CONTEXT
        });
      });
    }
  }

  registerUser(user: UserModel|undefined) {
    if (user && user.userId) {
      setUserId(user.userId.toString());
    }
    if (user) {
      this.init(user);
    }
  }

}

