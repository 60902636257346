<div class="ic-sidebar-indicators" *ngIf="indicators" id="cl-sidebar-indicators">

  <div class="ic-analysis-sidebar__description">
    <p>{{ 'analysis.sidebar.indicators.description' | translate }}</p>
  </div>

  <div class="ic-analysis-sidebar__section ic-sidebar-indicators__search">
    <label class="visually-hide" for="sidebar-indicators-search">
      {{ 'analysis.sidebar.indicators.Search within indicators' | translate }}
    </label>
    <span class="cl-form-text--icon" id="cl-sidebar-indicators-search">
      <cl-text-input
        [options]="{ inputValueChangeDelay: 250 }"
        [inputId]="'sidebar-indicators-search'"
        [inputClass]="'cl-form-text cl-form-text--underline'"
        [inputPlaceholder]="'analysis.sidebar.indicators.Search within indicators' | translate"
        (valueChangeDebounce)="onSearchInputChange($event)"
      ></cl-text-input>
      <cl-icon [iconId]="'search'" [color]="'color-gray80'" [size]="16"></cl-icon>
    </span>
  </div>

  <div class="ic-analysis-sidebar__main" id="cl-sidebar-indicator-details" [ngClass]="{
    'auto-height': detailsClosing,
    'fixed': detailsOpen
  }">

    <div *ngIf="visibleIndicatorGroups.length == 0" class="ic-analysis-sidebar__description">
      <p>{{'analysis.add indicator.No results' | translate}} {{searchQuery}}</p>
    </div>
    <ul class="ic-analysis-sidebar__groups" id="sidebar-indicator" cdkDropList [cdkDropListSortingDisabled]="true" [cdkDropListConnectedTo]="['analysis-table','analysis-visualizations']">
      <li *ngFor="let group of visibleIndicatorGroups" class="ic-analysis-sidebar__group">

        <span class="h6 ic-analysis-sidebar__groups__title"
          *ngIf="(group.name==='reputation' && reputationGrp===true) ||  (group.name!=='reputation')">{{ group.title }}
        </span>

        <ul class="ic-analysis-sidebar__list"
          *ngIf="(group.name==='reputation' && reputationGrp===true) || (group.name!=='reputation')">
          <ng-container *ngFor="let indicator of group.visibleIndicators; let i = index;">
          <li  (cdkDragEntered)="entered($event)" (cdkDragStarted)="dragStart($event)" (cdkDragReleased)="dragEnd($event);exited($event);"
             cdkDrag [ngClass]="!disableDrag(indicator) ? 'drag-indicator' : ''" [cdkDragData]="indicator" [cdkDragDisabled]="disableDrag(indicator)">
            <button
              type="button"
              class="cl-btn ic-analysis-sidebar__list__btn"
              (click)="openIndicator(indicator,indicatorDetails)"
              [ngClass]="!disableDrag(indicator) ? 'cursor-move':''"
            ><span class="ic-analysis-sidebar__list__title ic-sidebar-indicators__indicator__title">
                <cl-text-highlight
                  [text]="indicator.title"
                  [highlight]="searchQuery"
                  class="cursor-pointer"
                ></cl-text-highlight>
              </span>
              <span
                *ngIf="indicator.enabled"
                class="ic-analysis-sidebar__list__item__enabled"
                [attr.aria-label]="'analysis.sidebar.indicators.enabled' | translate"
                role="text"
              ></span>
              <cl-icon [iconId]="'caret-right'" class="cursor-pointer"></cl-icon>
            </button>
          </li>
            <hr *ngIf="IS_COLLAB_INDICATORS_ON && indicator?.subGroup && group?.visibleIndicators[i+1]?.subGroup && indicator?.subGroup != group?.visibleIndicators[i+1]?.subGroup">
            <li *ngIf="transferringItem === indicator.name" >
              <button
                type="button"
                class="cl-btn ic-analysis-sidebar__list__btn"
                (click)="openIndicator(indicator,indicatorDetails)"
              >
              <span class="ic-analysis-sidebar__list__title ic-sidebar-indicators__indicator__title">
                <cl-text-highlight
                  [text]="indicator.title"
                  [highlight]="searchQuery"
                ></cl-text-highlight>
              </span>
                <span
                  *ngIf="indicator.enabled"
                  class="ic-analysis-sidebar__list__item__enabled"
                  [attr.aria-label]="'analysis.sidebar.indicators.enabled' | translate"
                  role="text"
                ></span>
                <cl-icon [iconId]="'caret-right'"></cl-icon>
              </button>
            </li>
          </ng-container>

        </ul>

      </li>
    </ul>

  </div>

  <div class="ic-analysis-sidebar__footer">
    <div class="ic-analysis-sidebar__footer__help">
      <p>{{ 'analysis.sidebar.indicators.help' | translate }}</p>
      <p>
        <a rel="noopener noreferrer" class="cl-btn cl-btn--link cl-btn--with-icon" href="https://incites.help.clarivate.com/Content/Indicators-Handbook/ih-about.htm" target="_blank">
          <span class="cl-btn__text">
            {{ 'analysis.sidebar.indicators.Learn more about indicators' | translate }}
          </span>
          <cl-icon [iconId]="'link'"></cl-icon>
        </a>
      </p>
    </div>
  </div>

</div>

<div class="ic-analysis-sidebar__details"
  [attr.aria-hidden]="!detailsOpen"
  [ngClass]="{
    'active': detailsOpen,
    'closing': detailsClosing
  }"
 #indicatorDetails
>
  <ic-sidebar-indicator
    *ngIf="activeIndicator"
    [indicator]="activeIndicator"
    (close)="onDetailsClose()"
    (indicatorToggle)="onIndicatorToggle($event)"
  ></ic-sidebar-indicator>
</div>
