import {Injectable, OnDestroy} from '@angular/core';
import { TogglesState } from '@wos/toggles-core';
import {Subscription} from 'rxjs';
import {IcTogglesNgService} from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';

@Injectable({
    providedIn: 'root'
})
export class Globals implements OnDestroy {
  pssidToken: string = '';
  toggleData: TogglesState;
  subscription: Subscription;
  constructor(private icTogglesNgService: IcTogglesNgService) {
  }
  isSplitOn(toggleValue: string) {
    return this.toggleData && this.toggleData[toggleValue] === 'on';
  }

  setSplitIOToggles() {
    if (!this.toggleData) {
      this.subscription = this.icTogglesNgService.toggles().subscribe((toggles: TogglesState) => {
        this.toggleData = toggles;
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}