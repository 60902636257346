import { Injectable } from '@angular/core';
import { AnalysisItemModel } from 'pages/analysis/models/analysis-item.model';
import { SortableTableRowModel, SortableTableColumnModel, SortableTableValueFormatter } from '@ic/component-lib/src/components/modules/sortable-table/models';
import { AnalysisEntityService } from 'components/common/services/analysis-entity/analysis-entity.service';
import { EntityModel } from 'components/rest/services/entities-repository/entity.model';
import { TranslateService } from '@ngx-translate/core';
import { isObject, isPlainObject, isString, isUndefined } from 'lodash';

@Injectable()
export class AnalysisTableRowFactory {

  constructor(
    private analysisEntityService: AnalysisEntityService,
    private translate: TranslateService,
  ) {}

  createRows(
    items: AnalysisItemModel[],
    columns: SortableTableColumnModel[],
    entity: EntityModel,
    format: boolean = false,
    disableClickable: boolean = false,
  ) {
    return items.map((item, index) => this.createRow(item, columns, entity, index, format, disableClickable));
  }

  rowValueFormatters(columns: SortableTableColumnModel[]) {
    let rowValueFormatters: { [columnId: string]: SortableTableValueFormatter } = {};

    columns.forEach((column) => {
      if (column.valueFormatter) {
        rowValueFormatters[column.id] = column.valueFormatter;
      }
    });
    return rowValueFormatters;
  }

  createRow(
    item: AnalysisItemModel,
    columns: SortableTableColumnModel[],
    entity: EntityModel,
    rowIndex: number,
    format: boolean = false,
    disableClickable: boolean = false,
  ): AnalysisItemModel & SortableTableRowModel {
    const valueFormatters = this.rowValueFormatters(columns);
    const formatIndicatorValue = (column: SortableTableColumnModel, columnIndex: number) => {
      const columnId = column.id;
      if (disableClickable) {
        item[columnId] = this.flattenColumnValue(item[columnId]);
      }
      const columnValue = item[columnId];
      const isValueObject = columnValue !== null && !isString(columnValue) && isObject(columnValue);
      const clickable = isValueObject ? columnValue.clickable : undefined;
      const linkHref = isValueObject ? columnValue.url : undefined;
      const values = isValueObject ? columnValue.values : undefined;
      const value = isValueObject ? columnValue.value : columnValue;
      const rank = column.isDefaultSort && item.rank ? item.rank : undefined;
      const tabReportId = clickable ? columnValue.tabReportId : undefined;
      const valueNote = columnIndex === 0 ? this.getValueNoteForItem(item) : undefined;
      let formattedValue = format ? isUndefined(valueFormatters[columnId]) ? value : valueFormatters[columnId](value) : value;

      return {
        clickable,
        columnId,
        linkHref,
        ...(values && { values }),
        rank,
        tabReportId,
        valueNote,
        value: formattedValue,
      };
    };
    const getItemData = (cols: SortableTableColumnModel[]) => cols.map(formatIndicatorValue);
    const data = getItemData(columns);
    const name = this.analysisEntityService.getRowNameForEntity(item, entity);

    return { ...item, id: item.key, index: rowIndex, name, data };
  }

  // tslint:disable-next-line:no-any
  flattenColumnValue(columnValue: any) {
    if (isPlainObject(columnValue)) {
      return columnValue.value;
    }
    return columnValue;
  }

  private getValueNoteForItem(item: AnalysisItemModel) {
    switch (true) {
      case item.group:
        return this.translate.instant('analysis.table.group');
      case item.collaboration:
        return this.translate.instant('analysis.table.collaboration');
      default:
        return undefined;
    }
  }


}
