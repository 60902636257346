import { Component, ViewEncapsulation, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { isArray } from 'angular';
import { FolderItemModel } from 'components/rest/services/folders-repository/models';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import {SortableTableRowModel} from '@ic/component-lib/src/components/modules/sortable-table/models';
@Component({
  selector: 'ic-delete-content',
  templateUrl: './delete-content.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DeleteContentComponent {
  itemTitleToDelete: string | SortableTableRowModel[] | FolderModel[] | FolderItemModel[]  = '';
  deleteDataItem: SortableTableRowModel[] | FolderModel[] | FolderItemModel[] | string = '';
  @Output() onDelete = new EventEmitter();
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @Input() message: string = '';
  constructor(
    public translate: TranslateService,
    private tracker: EventsTrackerService,
  ) { }

  openModal(row: SortableTableRowModel[] | FolderModel[] | FolderItemModel[] | string) {
    this.deleteDataItem = row;
    if (isArray(row) && row.length > 0) {
      this.itemTitleToDelete = (row as (FolderModel | FolderItemModel)[]).reduce((acc: string, item: FolderItemModel | FolderModel) =>
        acc + item.title + `${row[row.length - 1].title !== item.title ? ', ' : ''} `, '');
    } else {
      this.itemTitleToDelete = row as SortableTableRowModel[];
    }
    this.overlayModal.openModal();
  }

  closeModal() {
    this.overlayModal.closeModal();
  }

  delete() {
    this.onDelete.emit(this.deleteDataItem);
    this.tracker.trackEvent('My saved', `Delete`, 'click');
  }
}