<div class="ic-sidebar-baselines" id="cl-sidebar-baselines">

  <div class="ic-analysis-sidebar__description">
    <p>{{ 'analysis.sidebar.baselines.description' | translate }}</p>
  </div>

  <div class="ic-analysis-sidebar__main ic-analysis-sidebar__section">

    <ul class="ic-sidebar-indicator__children">
      <li *ngFor="let baseline of baselines">

        <h4 class="h5 ic-sidebar-indicator__child__title">{{ baseline.title }}</h4>

        <p *ngIf="baseline.description" class="ic-sidebar-indicator__child__description">
          {{ baseline.description }}
        </p>

        <button
          type="button"
          class="cl-btn cl-btn--with-icon ic-sidebar-indicator__child__toggle-btn cl-sidebar-indicator-toggle"
          (click)="onToggleClick(baseline)"
          [disabled]="baseline.disabled"
          [ngClass]="{
            'cl-btn--primary-outline': !baseline.enabled,
            'cl-btn--primary': baseline.enabled
          }"
        >
          <ng-container *ngIf="baseline.enabled">
            <cl-icon [iconId]="'close-small'" [color]="'color-white'" [size]="8"></cl-icon>
            <span class="cl-btn__text">{{ 'analysis.sidebar.baselines.Remove' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="!baseline.enabled">
            <cl-icon [iconId]="'add'" [size]="8" [color]="baseline.disabled ? 'color-white' : 'color-teal'"></cl-icon>
            <span class="cl-btn__text">{{ 'analysis.sidebar.baselines.Add' | translate }}</span>
          </ng-container>
        </button>

      </li>
    </ul>

  </div>

  <div class="ic-analysis-sidebar__footer">
    <div class="ic-analysis-sidebar__footer__help">
      <p>{{ 'analysis.sidebar.baselines.help' | translate }}</p>
      <p>
        <a rel="noopener noreferrer" class="cl-btn cl-btn--link cl-btn--with-icon" href="https://incites.help.clarivate.com/Content/Indicators-Handbook/ih-baselines.htm" target="_blank">
          <span class="cl-btn__text">
            {{ 'analysis.sidebar.baselines.learnMore' | translate }}
          </span>
          <cl-icon [iconId]="'link'"></cl-icon>
        </a>
      </p>
    </div>
  </div>

</div>
