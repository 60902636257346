import { Injectable } from '@angular/core';
import { extend, forEach, groupBy, cloneDeep, isArray } from 'lodash';
import { Indicator } from '../../../common/interfaces/indicator.interface';
import {Globals} from 'components/shared/globalData';
import {Subscription} from 'rxjs';
import {EnvironmentService} from 'components/app/services/environment/environment.service';

@Injectable()
export class DiagramTransformService {

  subscription: Subscription;
  env = this.environmentService.getEnvironment();
  constructor(private globalData: Globals, private environmentService: EnvironmentService) {
    this.globalData.setSplitIOToggles();
  }

  many2many(data: Array<{[index: string]: {}}>) {
    let nodes: Array<{[index: string]: {}}> = [];

    forEach(groupBy(data, 's_id'), (value, key) => {
      const first = value[0];
      let links: Array<{[index: string]: {}}> = [];
      let node;

      forEach(value, (link) => {
        links.push(extend({ id: link.t_id }, link));
      });

      node = extend({ id: key, name: first.s_name, links: links }, first);
      node.value = node.s_value;
      nodes.push(node);
    });

    return nodes;
  }

  radar(data: Array<{[index: string]: {}}>, indicators: Array<Indicator>) {
    let transformed: Array<{}> = [];
    let items = cloneDeep(data);

    if (isArray(items)) {
      let isCollabIndicatorsOn = this.env.splitioTreatment.isCollabIndicatorsOn;
      if (this.globalData.isSplitOn(isCollabIndicatorsOn)) {
        items.forEach((item) => {
          indicators.forEach((indicator) => {
            transformed.push(extend({}, item, {
              'indicator-name': indicator.title,
              'indicator-value': item[indicator.name],
              'indicator-unit': indicator.unit,
              'indicatorColumn': indicator.name
            }));
          });
        });
      } else {
        items.forEach((item) => {
          indicators.forEach((indicator) => {
            transformed.push(extend({}, item, {
              'indicator-name': indicator.title,
              'indicator-value': item[indicator.name],
              'indicator-unit': indicator.unit
            }));
          });
        });
      }

    }

    return transformed;
  }


}